<template>
  <div class='template'>    
      <transition name="fade" appear>
      <article class="images" :class="[collage.length === 3 ? 'three-images' : '']">
          <div v-for="image in collage" :key="image.title" :style="{backgroundImage:`url(${image.file})`}" />
      </article>        
      </transition>
      <article class='text-content' :class="[showInformation ? 'show' :'']">
          <transition name="slide" appear>
            <div>
                <div class='title-holder'>
                    <h1 v-html="title"/>
                    <ToggleButton @toggled="setToggled" v-if="copy !== '' || gallery.length"/>                                        
                </div>
                <div class='copy-holder'>
                    <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="showVideo"/>
                    <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>
                    <div class="linear-holder">
                        <LinearButton :to="page?.previous" previous/>
                        <LinearButton :to="page?.next"/>
                    </div> 
                </div>
            </div>
          </transition>          
      </article>      
      <div class="linear-holder">
            <LinearButton :to="page?.previous" previous/>
            <LinearButton :to="page?.next"/>
      </div> 
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ToggleButton from '@/components/ToggleButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Floating Collapsible Copy',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle,
        ToggleButton
    },
    methods : {
        setToggled(value){
            this.showInformation = value;
        }
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            showInformation:false,   
            imagesFromGallery:2,   
            copy:this.page.copy   
        }
    },
   computed: {
        collage() {
            let images = [];
            if(this.page.background) {
                images.push({
                    file:this.page.background,
                    title:this.page.title
                });
            }
            for (let index = 0; index < Math.min(this.imagesFromGallery, this.page.gallery?.length); index++) {
                const element = this.page.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },        
        gallery() {
            let images = [];        
            //some pages put images from the gallery on screen; for instance the UpToThreeImagesLeft template has up to 3 images visible on the left; 
            //so if the rep does add 3 images; two of which would of been in the gallery in the CMS; then they need to be removed from the gallery
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },
        title() {
            let title = this.page?.title;
            if(title) {
                const titleArray = title.split(" ");
                let lastWords = "";
                let firstWords = "";
                if(titleArray.length > 3) {
                    lastWords = titleArray.splice(3).join(" ");
                }
                firstWords = titleArray.join(" ");
                return lastWords === '' ? `${firstWords}` : `${firstWords} <span class="nobr">${lastWords}</span>`;
            }
            return "";
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .template > .linear-holder {
            display: none;
            position: relative;
            height:90px;
        }
    }  
    .copy-holder > .linear-holder {
        position: relative;
        padding-top:16px;
        .linear {
            position: static;
        }
        @media (min-width:700px) {  
            display: none;
        }
    }      
    .copy {
        max-height: 435px;
        overflow: auto;
        padding-top:20px;
        box-sizing: border-box;
        
    }
    .title-holder {
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap:16px;
        button {
            white-space: nowrap;
        }
    }
    .ui {
        margin-top: 0;
        padding-top:20px;
    }
    .copy-holder {
        max-height:0px;
        transition: max-height 0.2s ease-in-out;
        overflow: hidden;        
        height:100%;
    }
    .text-content {
        background: linear-gradient(134.76deg, #202020 0%, #121212 100%);                
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact;     
        box-sizing: border-box;
        color: #F5F5F5;        
        position: absolute;
        bottom:23px;
        left:50%;
        
        width:100%; 
        max-width:475px;
        transition: transform 0.2s ease-in-out;
        transform:translateX(-50%);     
        padding:13px 24px;
        border-radius: 10px;
        &.show {            
            .copy-holder {
                max-height:450px;
            }
        }        
        @media print {
            > div {
                grid-template-columns: max-content 1fr;
                .copy {
                    padding-right:0;
                }
                div:not(.copy) {
                    margin-left:0;
                }
            }
        }                    
    }
    h1 {
        margin:0 16px;
        text-transform: uppercase;
        margin:0;
        color: #F5F5F5;
        font-size: 1.5rem;
        letter-spacing: 0;
        line-height: 1;
        position: relative;     
        :deep() span {
            display: block;
        }   
    }    
    .images {        
        display:grid;
        gap:1px;        
        height:100%; 
        min-height:50vh;
        background: #F5F5F5;
        overflow: hidden;       
        > div {
            background-size: cover;
            background-position: center;
            -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
            color-adjust: exact;     
            overflow: hidden;
            display: flex;
            flex-direction: column;            
        }
        &.three-images {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 50% 50%;
            > div {  
                position: relative;              
                &:last-child {
                    justify-content: flex-end;
                    grid-row-start: 1;
                    grid-row-end: 3;
                    grid-column-start: 2;
                    grid-column-end: 2;
                }
            }
        }             
    }
</style>